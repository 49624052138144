<template>
  <div id="afbs-timer">
    <Timer :endDate="new Date(endDate)" />
  </div>
</template>

<script>
import Timer from './components/Timer.vue';

export default {
  name: 'App',
  props: {
    endDate: {
      type: String,
      default: '2023-09-18',
    },
  },
  components: {
    Timer,
  },
};
</script>
<style scoped>
#afbs-timer {
  display: flex;
  justify-content: center;
}
</style>
