<template>
  <div class="timer-container">
    <div class="timer-items">
      <ProgressSlot :max="365" :angle="Math.round(hour / 24)"
        >Days</ProgressSlot
      >
    </div>
    <div class="timer-items">
      <ProgressSlot :max="24" :angle="23">Hours</ProgressSlot>
    </div>
    <div class="timer-items">
      <ProgressSlot :max="60" :angle="min">Minutes</ProgressSlot>
    </div>
    <div class="timer-items">
      <ProgressSlot :max="60" :angle="parseInt(sec)">Seconds</ProgressSlot>
    </div>
  </div>
</template>
<script>
import ProgressSlot from './ProgressSlot.vue';
//parseInt(sec)
export default {
  name: 'Timer',
  components: {
    ProgressSlot,
  },
  data() {
    return {
      now: new Date(),
      timer: null,
    };
  },
  computed: {
    hour() {
      let h = Math.trunc((this.endDate - this.now) / 1000 / 3600);
      return h;
    },
    min() {
      let m = Math.trunc((this.endDate - this.now) / 1000 / 60) % 60;
      return m;
    },
    sec() {
      let s = Math.trunc((this.endDate - this.now) / 1000) % 60;
      return s;
    },
  },
  watch: {
    endDate: {
      immediate: true,
      handler(newVal) {
        if (this.timer) {
          clearInterval(this.timer);
        }
        this.timer = setInterval(() => {
          this.now = new Date();
          if (this.negative) return;
          if (this.now > newVal) {
            this.now = newVal;
            this.$emit('endTime');
            clearInterval(this.timer);
          }
        }, 1000);
      },
    },
  },
  props: {
    endDate: {
      // pass date object till when you want to run the timer
      type: Date,
      default() {
        return new Date();
      },
    },
    negative: {
      // optional, should countdown after 0 to negative
      type: Boolean,
      default: false,
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
<style scoped>
.timer-container {
  margin: 2rem 1rem 1rem 1rem;
  display: flex;
  background: transparent linear-gradient(180deg, #eef0f2 0%, #e5e5e5b7 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #707070;
  background: transparent linear-gradient(180deg, #eef0f2 0%, #e5e5e5b7 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #707070;
  border-radius: 8rem;
  max-width: 800px;
}

.timer-items {
  width: 200px;
  height: 200px;
  color: red;
}
@media screen and (max-width: 480px) {
  .timer-items {
    width: 95px;
    height: 95px;
  }
}
</style>
