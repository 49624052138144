<template>
  <div class="wrapper">
    <svg>
      <circle
        :style="css"
        class="meter-responsive-not meter-1"
        cx="85"
        cy="85"
        r="75"
      />
      <circle
        :style="responsive"
        class="meter-responsive meter-1"
        cx="47.5"
        cy="47.5"
        r="38"
      />
    </svg>
    <div class="inside-circle">
      <span class="big">{{ angle }}</span>
      <span class="small"><slot></slot></span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Progress',
  data() {
    return {
      test: 90,
    };
  },
  props: {
    angle: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 360,
    },
  },
  methods: {
    size() {
      //https://codepen.io/alvarotrigo/pen/abLvEjW
      //(360/100) * 75 = 270
    },
    angleToSVG(arraySize) {
      //this.angle * (468 / this.max);
      return arraySize - this.angle * (arraySize / this.max);
    },
  },
  computed: {
    angleToDesk() {
      //this.angle * (468 / this.max);
      return this.angleToSVG(468);
    },
    angleToMobile() {
      return this.angleToSVG(238);
    },
    css() {
      return {
        'stroke-dashoffset': this.angleToDesk,
      };
    },
    responsive() {
      return {
        'stroke-dashoffset': this.angleToMobile,
      };
    },
  },
};
</script>
<style scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
svg {
  width: 170px;
  height: 170px;
  margin: 1em;
}
.bg {
  fill: none;
  stroke-width: 10px;
  stroke: #1a2c34;
}

[class^='meter-'] {
  fill: none;
  stroke-width: 7px;
  /* stroke-linecap: round; */
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}
.meter-responsive {
  display: none;
}
.meter-1 {
  stroke-dasharray: 468;
  stroke: #f08700;
  /* animation: progress-1 1s ease-out; */
}
.meter-2 {
  stroke: lime;
  stroke-dasharray: 360;
  stroke-dashoffset: 160;
  animation: progress-2 0.75s ease-out;
}
.meter-3 {
  stroke: magenta;
  stroke-dasharray: 360;
  stroke-dashoffset: 250;
  animation: progress-3 0.5s ease-out;
}

@keyframes progress-1 {
  from {
    stroke-dashoffset: 360;
  }
  to {
    stroke-dashoffset: 180;
  }
}

@keyframes progress-2 {
  from {
    stroke-dashoffset: 360;
  }
  to {
    stroke-dashoffset: 160;
  }
}

@keyframes progress-3 {
  from {
    stroke-dashoffset: 360;
  }
  to {
    stroke-dashoffset: 250;
  }
}

.inside-circle {
  color: #f08700;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 2em;
  display: flex;
  flex-direction: column;
}
.inside-circle .big {
  font: normal normal bold 4rem FuturaBold;
  line-height: 3.1rem;
}
.inside-circle .small {
  font: normal normal bold 1.8rem FuturaThin;
}

@media screen and (max-width: 480px) {
  .meter-responsive-not {
    display: none;
  }
  svg {
    width: 95px;
    height: 95px;
    margin: 0rem;
  }
  [class^='meter-'] {
    fill: none;
    stroke-width: 3px;
  }
  .meter-responsive {
    display: flex;
  }
  .inside-circle .big {
    font: normal normal bold 2rem FuturaBold;
    line-height: 1.5rem;
  }
  .inside-circle .small {
    font: normal normal bold 1rem FuturaThin;
  }

  .meter-1 {
    stroke-dasharray: 238;
  }
}
</style>
